import { getAnalytics, logEvent } from "firebase/analytics";

const googleAnalyticsLogger = () => {

    function log(eventName: string) {
        logEvent(getAnalytics(), eventName);
    }

    function logEventWithParams(eventName: string, eventParams?: any) {
        logEvent(getAnalytics(), eventName, eventParams);
    }

    return {
        log,
        logEventWithParams,
    };
}
 
export default googleAnalyticsLogger;