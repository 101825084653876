import Navbutton from './Navbutton';
import { NavbuttonData } from '../interfaces/navbutton-data';
import { Drawer as MantineDrawer, Image, Flex, Divider, createStyles } from '@mantine/core';
import WeldproIcon from '../assets/weldpro/weldpro-icon.png';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles(() => ({
    drawerBackgroundColor: {
        background: 'var(--mantine-color-black) !important'
    }
}));


const Drawer: React.FC<{ opened: boolean, close: () => void, navData: NavbuttonData[] }> = ({opened, close, navData}) => {

    const { classes } = useStyles();

    const navigate = useNavigate();

    return (
        <MantineDrawer
            opened={opened}
            onClose={close}
            closeOnClickOutside={true}
            closeOnEscape={true}
            overlayProps={{ opacity: 0.5 }}
            withCloseButton={false}
            closeButtonProps={{ 'aria-label': 'Close modal' }}
            zIndex={1000000}
            padding="xl"
            classNames={{ header: classes.drawerBackgroundColor, content: classes.drawerBackgroundColor }}
            >
            <MantineDrawer.Header display="inline-flex" className='drawer-header' style={{ width: '100%' }}>
                <MantineDrawer.Title>
                    <Image src={WeldproIcon} height={50} width={50} style={{ maxWidth: '50px' }} className='logo-hover' />
                </MantineDrawer.Title>
                <MantineDrawer.CloseButton />
            </MantineDrawer.Header>


            <MantineDrawer.Body>
                <Divider my="xs" color='var(--mantine-color-white)' />
                        
                <Flex display="flex" direction="column" gap={30}>
                    {
                        navData.map((data, index) => (
                            <Navbutton 
                                key={index}
                                data={data}
                                onClick={() => { navigate(data.route); close(); }}
                                >
                            </Navbutton>  
                        ))
                    }
                </Flex>

            </MantineDrawer.Body>            

        </MantineDrawer>
    );
}
 
export default Drawer;