import ReactDOM from "react-dom/client";
import App from "./App";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB5LFvUCwRXeHTV4kPNgnkbmYHDlSjY6EQ",
  authDomain: "weldpro-78c2a.firebaseapp.com",
  projectId: "weldpro-78c2a",
  storageBucket: "weldpro-78c2a.appspot.com",
  messagingSenderId: "628161324472",
  appId: "1:628161324472:web:0b5c566dd7905654595b4d",
  measurementId: "G-029YQZHXW6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'broj_posjeta');


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <App />
);
