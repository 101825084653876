import { MantineProvider, MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  headings: {
    fontFamily: 'Noto Sans',
    fontWeight: 700,
    sizes: {
        h2: {
          fontSize: '2rem',
          lineHeight: 1.2,
        }
    }
  },
  lineHeight: 2.2,
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
  spacing: {
    xs: '0.375rem', // 6px
  },
  colorScheme: 'light',
  colors: {
    // scss color acessor: var(--mantine-color-backgroundDark-6)
    weldproGray: ['#2B2A28'],
    backgroundDark: ['#282729', '#3498db', '#3498db', '#3498db', '#3498db', '#3498db', '#3498db', '#3498db', '#3498db', '#3498db'],
  },
  components: {
    AppShell: {
      styles: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'var(--mantine-color-gray-8)',
        },
        body: {
          backgroundColor: 'var(--mantine-color-gray-8)',
        }
      }
    },
    Title: {
      styles: {
        root: {
          '&:is(h1)': { color: 'var(--mantine-color-white)' },
          '&:is(h2)': { color: 'var(--mantine-color-white)' },
        },
      },
    },
    Text: {
      styles: {
        root: {
          color: 'var(--mantine-color-white)',
          '&:is(p)': { color: 'var(--mantine-color-gray-0)' },
        }
      }
    },
    Anchor: {
      styles: {
        root: {
          color: 'var(--mantine-color-blue-6)'
        }
      }
    },
    Input: {
      styles: {
        input: {
          background: 'var(--mantine-color-weldproGray-0)',
          color: 'var(--mantine-color-white)',
          borderColor: 'var(--mantine-color-blue-9)',
          ':focus-within': {
            borderColor: 'var(--mantine-color-blue-6)',
          }
        },
        icon: {
            color: 'var(--mantine-color-white)',
        }
      }
    },
    TextInput: {
      styles: {
        label: {
          color: 'var(--mantine-color-gray-0)',
        },
      }
    },
    Checkbox: {
      styles: {
        label: {
          color: 'var(--mantine-color-gray-2)'
        },
        input: {
          background: 'var(--mantine-color-backgroundDark-0)'
        }
      }
    },
    Drawer: {
      styles: {
        root: {
          backgroundColor: 'var(--mantine-color-black)'
        },
        close: {
          color: '(--mantine-color-red-9)'
        }
      }
    },
  },
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  
  return (
    <MantineProvider withNormalizeCSS withGlobalStyles withCSSVariables theme={theme}>
      { children }
    </MantineProvider>
  );
}
