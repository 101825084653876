import { UnstyledButton, createStyles } from '@mantine/core';
import { NavbuttonData } from '../interfaces/navbutton-data';
import { ReactEventHandler } from 'react';

const useStyles = createStyles((theme) => ({
    navbuttonContainer: {
        position: 'relative',
        padding: theme.spacing.sm,
        paddingBottom: 0
    },
    
    navbutton: {
        padding: 0,
        width: '100%',
        position: 'relative',
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.md,
        fontWeight: 500,
        borderBottom: '2px solid transparent',
        color: theme.colors.gray[2],
        transition: 'border-color 100ms ease, color 100ms ease',
    
        '&:hover': {
            color: theme.white,
        },
    
        '&-active': {
            color: theme.white,
            borderBottom: `2px solid ${theme.colors.blue[6]}`,
        }
    },
    
    navbuttonPageIndicator: {
        position: 'absolute',
        background: theme.white,
        height: '3px',
        bottom: '-10px',
        left: 0,
        borderRadius: '3px',
        width: '100%'
    }
}))

const Navbutton: React.FC<{ 
    data: NavbuttonData, 
    active?: boolean, 
    onClick?: ReactEventHandler 
}> 
    = ({ data, active = false, onClick }) => {

    const { classes } = useStyles();

    return (
        <div className={classes.navbuttonContainer} onClick={onClick}>
            <UnstyledButton                
                className={classes.navbutton}
                >
                { data.page }
            {
                active ? <span className={classes.navbuttonPageIndicator}></span> : ''
            }
            </UnstyledButton>
        </div>
    );
}
 
export default Navbutton;