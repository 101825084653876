import IconFacebook from '../assets/icons/facebook.png';
import IconInstagram from '../assets/icons/instagram.png';
import IconTiktok from '../assets/icons/tik-tok.png';
import { NavbuttonData } from '../interfaces/navbutton-data';

export const SOCIAL_MEDIA = [
    {
        icon: IconFacebook,
        link: 'https://www.facebook.com/profile.php?id=100066593593557',
        title: 'Facebook',
    },
    {
        icon: IconInstagram,
        link: 'https://www.instagram.com/weldpro_aluminijske_ograde?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
        title: 'Instagram',
    },
    {
        icon: IconTiktok,
        link: 'https://www.tiktok.com/@weldpro_?_t=8iyZQYxQXjN&_r=1',
        title: 'TikTok',
    }
];

export const ROUTES_DATA: NavbuttonData[] = [
    {
        page: 'Početna',
        route: '/',
    },
    {
        page: 'Galerija',
        route: '/galerija',
    },
    {
        page: 'Proizvodnja',
        route: '/proizvodnja',
    },
    {
        page: 'Zatraži ponudu',
        route: '/zatrazi-ponudu',
    }
];