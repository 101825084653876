import { ActionIcon, Flex, Image } from "@mantine/core";
import { SOCIAL_MEDIA } from "../data/weldpro-data";

const SocialMediaLinks: React.FC = () => {

    const socialMedia = SOCIAL_MEDIA;

    return (
        <Flex direction="row" justify="center" gap={20}>
            {
                socialMedia.map((site, index) => (
                    <ActionIcon key={index} size="md" variant="transparent" title={site.title} component="a" href={site.link}>
                        <Image src={site.icon} alt={site.title} />
                    </ActionIcon>
                ))
            }
        </Flex>
    );
}
 
export default SocialMediaLinks;