import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
//import Home from './pages/Home';
const Home = lazy(() => import('./pages/Home'));
const Products = lazy(() => import('./pages/Products'));
const Contact = lazy(() => import('./pages/Contact'));
const Gallery = lazy(() => import('./pages/Gallery'));


export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/galerija" element={<Gallery />} />
      <Route path="/proizvodnja" element={<Products />} />
      <Route path="/zatrazi-ponudu" element={<Contact />} />
    </Routes>
  );
}