import { Image, Container, Flex, Text, Anchor, createStyles, Group } from "@mantine/core";
import { IconCode, IconMail } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    anchorStyle: {
        color: '#fff',

        ...theme.fn.hover({
            height: 35,
            width: 35,
        })
    }
}));

const CreatorContact: React.FC = () => {

    const { classes } = useStyles();

    return (
        <Container pos="relative" maw="100%"
            style={{
                border: '1px solid var(--mantine-color-gray-6)',
                borderRadius: '0.425rem',
                boxSizing: 'border-box',
                overflow: 'hidden',

                display: 'flex',
                justifyContent: 'center'
            }}
            >

            <Image 
                src="https://raw.githubusercontent.com/tmarkovica/wallpaper/master/600x600.png"                
                opacity={0.4}
                pos="absolute"
                top={0} left={0}
                fit="cover"
                >
            </Image>

                <Group style={{ zIndex: 1 }} maw="100%" position="center" grow={false} spacing={0}>

                    <div style={{ zIndex: 2, display: 'grid', gridTemplateColumns: 'calc(100% - 30px) 30px', padding: '0 15px' }}>
                        <Text component="b" c="var(--mantine-color-orange-6)" mr={15} miw="max-content">Razvoj softvera</Text>
                        <Anchor href="https://oakbeam.hr" title="https://oakbeam.hr" align="center" display="flex"
                            className={classes.anchorStyle}
                            > 
                            <IconCode width={30} height={30} className={classes.anchorStyle} />
                        </Anchor>
                    </div>

                    {/* <Flex align="center" style={{ zIndex: 1 }} wrap="nowrap" maw="100%" px={15}>
                        <IconPhone width={24} height={24} color="#fff" />
                        <Text component="p" ml={10} miw="max-content">+385 95 527 9214</Text>
                    </Flex> */}

                    <Flex align="center" style={{ zIndex: 1 }} wrap="nowrap" maw="100%" px={15}>
                        <IconMail width={24} height={24} color="#fff" />
                        <Text component="p" ml={10} miw="max-content">info@oakbeam.hr</Text>
                    </Flex>
                </Group>

        </Container>
    );
}

export default CreatorContact;