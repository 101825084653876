import { Burger, Flex, Header as MantineHeader, MediaQuery, Image, Container } from "@mantine/core";
import './Header.scss';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbuttonData } from "../../interfaces/navbutton-data";
import Navbutton from "../Navbutton";
import { useDisclosure } from "@mantine/hooks";
import Drawer from "../Drawer";
import { ROUTES_DATA } from "../../data/weldpro-data";
import WeldproIcon from '../../assets/weldpro/weldpro-icon.png';
import SocialMediaLinks from "../SocialMediaLinks";
import googleAnalyticsLogger from "../../services/googleAnalyticsLogger";

const navigationData: NavbuttonData[] = ROUTES_DATA;

const Header: React.FC = () => {

    const locationChange = useLocation();

    useEffect(() => {
        googleAnalyticsLogger().log(locationChange.pathname);
    }, [locationChange]);

    const navigate = useNavigate();

    const navigationButtonClick = (path: string) => {
        navigate(path, { replace: true })
    };

    const [drawerState,  { close, toggle }] = useDisclosure();

    return (
        <>
            <MantineHeader height={120} className="header">

                <Flex display="flex" justify="space-between" align="end" className="header-content">

                    <Flex direction="row" align="end">
                            <MediaQuery largerThan="md" styles={{display: 'none'}}>
                                <Burger
                                    opened={drawerState}
                                    onClick={toggle}
                                    className="burger"
                                    color="var(--mantine-color-red-9)"
                                    />
                            </MediaQuery>
                            
                            <Image src={WeldproIcon} height={60} width={60} className='logo-hover' alt="Weldpro logo" />
                    </Flex>

                    <Flex direction="row" align="end" justify="end" w="100%" maw="100%" >

                        <Container p={0} m={0}>
                            <SocialMediaLinks />
                        </Container>

                        <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                            <Flex
                                gap={0}
                                justify="center"
                                align="flex-end"
                                direction="row"
                                wrap="nowrap"
                                ml={100}
                                >
                                {
                                    navigationData.map((data, index) => (
                                        <Navbutton 
                                            key={index} 
                                            data={data}
                                            active={locationChange.pathname === data.route}
                                            onClick={() => navigationButtonClick(data.route)}
                                            >
                                        </Navbutton>
                                    ))
                                }                    
                            </Flex>
                        </MediaQuery>
                    </Flex>

                    
                </Flex>
                
            </MantineHeader>

            <Drawer opened={drawerState} close={close} navData={navigationData}></Drawer>
        </>
    );
}
 
export default Header;