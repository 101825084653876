import './styles/global.scss';
import { AppShell } from "@mantine/core";
import { ThemeProvider } from "./ThemeProvider";
import AllRoutes from "./routes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import useMetaTags from './hooks/useMetaTags';

export default function App() {

  useMetaTags();

  return (
    <ThemeProvider>

      <BrowserRouter>

        <AppShell 
          header={<Header />}
          footer={<Footer />}
          fixed={false}
          padding={0}
          style={{ overflowX: 'hidden' }}
          mih="100vh"
          >
            <Suspense fallback={undefined}>              
              <AllRoutes />
            </Suspense>
        </AppShell>

      </BrowserRouter>

    </ThemeProvider>
  );
}