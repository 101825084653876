import { useEffect } from "react";

const useMetaTags = () => {

    function addCanonical() {
        const currentUrl = window.location.href;
        const newCanonicalLink = document.createElement('link');
        newCanonicalLink.rel = 'canonical';
        newCanonicalLink.href = currentUrl;
        document.head.appendChild(newCanonicalLink);
    }

    function addUrl() {
        const currentUrl = window.location.href;        
        const element = document.createElement('meta');
        element.setAttribute('property', 'og:url');
        element.content = currentUrl;
        document.head.appendChild(element);
    }
    
    useEffect(() => {
        addCanonical();
        addUrl();
    }, []);
}
 
export default useMetaTags;