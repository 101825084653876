import { Flex, Footer as MantineFooter, Image, Divider, Container, Text, Avatar } from "@mantine/core";
import classes from './Footer.module.scss';
import { IconUser, IconMail, IconMapPin, IconPhone} from "@tabler/icons-react";
import IconViber from '../../assets/icons/viber.png';
import IconWhatsapp from '../../assets/icons/whatsapp.png';
import SocialMediaLinks from "../SocialMediaLinks";
import CreatorContact from "../CreatorContact";
import IconReact from "../../assets/react-logo.png"


const Footer: React.FC = () => {

    const email = 'info@weldpro.hr';

    return (
        <MantineFooter height="fit-content" fixed={false} className={classes.footerContainer}>
            <div className={classes.footer}>

                <Flex display="flex" direction="row" justify="space-evenly" wrap="wrap-reverse" align="center" gap={80} style={{ minWidth: '100%' }}>

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22151.223219953863!2d17.16869123681528!3d46.05303494760947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4767d1c7bfb519c5%3A0x23ae77b4861827a8!2sWeldPro%20-%20aluminijske%20ograde!5e0!3m2!1shr!2shr!4v1704364763450!5m2!1shr!2shr"
                        width="600" height="450" style={{border: 0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                        title="Naša lokacija"
                        className={classes.googleMapsFrame}
                        >
                    </iframe>

                    <Flex gap={20} direction="column">
                        
                        <h3 style={{ color: 'var(--mantine-color-gray-1)' }}>Kontaktirajte nas</h3>

                        <div className={classes.InfoItem}>
                            <IconUser />
                            <label>Vl. Nikola Krstić</label>
                        </div>

                        <div className={classes.InfoItem}>
                            <IconMail />
                            <label><a href="mailto:{{email}}" style={{ color: 'var(--mantine-color-blue-5)'}}>{ email }</a></label>
                        </div>

                        <div className={classes.InfoItem}>
                            <IconPhone />
                            <label>+385 92 370 8023</label>
                            <Image src={IconViber} className={classes.viber_whatsapp} width={25} height={25} title="Viber" alt="Viber kontakt" />
                            <Image src={IconWhatsapp} className={classes.viber_whatsapp} width={25} height={25} title="Whatsapp" alt="Whatsapp kontakt" />
                        </div>

                        <div className={classes.InfoItem}>
                            <IconMapPin />
                            <label>Petra Preradovića 18, Ferdinandovac</label>
                        </div>


                        <div className={classes.socialMedia}>
                            <SocialMediaLinks />
                        </div>

                    </Flex>

                </Flex>

                {/* <Divider mt={50} mb={10} w="80vW" /> */}

                    <Container maw="100%" w="100%" mt={40} p={0}
                        style={{ 
                            display: 'flex', 
                            justifyContent: "space-between", 
                            alignItems: 'flex-end', 
                            flexWrap: 'wrap' 
                        }}>
                        
                        <Container px="xl" size="xs" m={0} display="flex" style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                            <Text component="label" mr={15}>Powered by</Text>
                            <Avatar src={IconReact} size="xs" h={20}></Avatar>
                        </Container>

                        <Container p={0} m={0} px="xl" size="xs">
                            <CreatorContact />
                        </Container>

                    </Container>

                <Divider mt={10} w="100vw" />    

                <label className={classes.copyright && classes.darkerText}>© Weldpro 2024.</label>
                
            </div>
        </MantineFooter>
    );
}
 
export default Footer;